// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-bloghome-index-js": () => import("/var/difm/client/src/components/bloghome/index.js" /* webpackChunkName: "component---src-components-bloghome-index-js" */),
  "component---src-components-blogarticle-index-js": () => import("/var/difm/client/src/components/blogarticle/index.js" /* webpackChunkName: "component---src-components-blogarticle-index-js" */),
  "component---src-components-pages-home-js": () => import("/var/difm/client/src/components/pages/home.js" /* webpackChunkName: "component---src-components-pages-home-js" */),
  "component---src-components-pages-search-js": () => import("/var/difm/client/src/components/pages/search.js" /* webpackChunkName: "component---src-components-pages-search-js" */),
  "component---src-components-pages-quote-js": () => import("/var/difm/client/src/components/pages/quote.js" /* webpackChunkName: "component---src-components-pages-quote-js" */),
  "component---src-components-pages-thankyou-js": () => import("/var/difm/client/src/components/pages/thankyou.js" /* webpackChunkName: "component---src-components-pages-thankyou-js" */),
  "component---src-components-pages-faq-js": () => import("/var/difm/client/src/components/pages/faq.js" /* webpackChunkName: "component---src-components-pages-faq-js" */),
  "component---src-components-pages-about-js": () => import("/var/difm/client/src/components/pages/about.js" /* webpackChunkName: "component---src-components-pages-about-js" */),
  "component---src-components-pages-contact-js": () => import("/var/difm/client/src/components/pages/contact.js" /* webpackChunkName: "component---src-components-pages-contact-js" */),
  "component---src-components-pages-terms-js": () => import("/var/difm/client/src/components/pages/terms.js" /* webpackChunkName: "component---src-components-pages-terms-js" */),
  "component---src-components-pages-partners-js": () => import("/var/difm/client/src/components/pages/partners.js" /* webpackChunkName: "component---src-components-pages-partners-js" */),
  "component---src-components-pages-request-js": () => import("/var/difm/client/src/components/pages/request.js" /* webpackChunkName: "component---src-components-pages-request-js" */),
  "component---src-components-pages-privacy-js": () => import("/var/difm/client/src/components/pages/privacy.js" /* webpackChunkName: "component---src-components-pages-privacy-js" */),
  "component---src-pages-404-js": () => import("/var/difm/client/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/difm/client/.cache/data.json")

